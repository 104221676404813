export const environment = {
  production: true,
  urlBase: 'https://emipresalesapi.azurewebsites.net',
  urlBaseMiddleware: 'http://middleware.grupoemi.com:6080/',
  urlMercadoPago: 'https://app-pasarela-backend-pdn.azurewebsites.net/',
  urlBaseLocal: 'https://localhost:7108/',
  varAmbienteCol: 'CoPlsUy',
  varAmbienteUy: 'UyPrd',
  token_payment:"Basic RW1pX0FmaTNhNG8/OkdWdT8yMVR6"
};
